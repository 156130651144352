<script>
import { useAuthStore } from '@/stores/authStore';
import axios from 'axios';
//import AddonComponent from '../components/AddonComponent.vue';
import ModalPopupTerms from '../components/ModalPopupTerms.vue';

export default {
  name: 'ShoppingCart',
  components: {
    ModalPopupTerms
    // AddonComponent
  },
  data() {
    const authStore = useAuthStore();

    return {
      items: [],
      authStore,
      paymentOptionSelected: '',
      shippingOptionSelected: '',
      masterItemIds: [],
      memo: '',
      profile: {
        id: 0,
        state: 'HI',
        country: 'US',
        billto_state: 'HI',
        billto_country: 'US'
      },
      errorMessage: '',
      zip: '',
      validZipMessage: '',
      validZip: true,
      shippingCalculated: false,

      validTerms: true, // intially true, will compare against termsClicked
      termsClicked: 0,
      country_codes: ['US', 'CA'],
      state_codes: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',

        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'NT',
        'NU',
        'ON',
        'PE',
        'QC',
        'SK',
        'YT'
      ],

      // Authorize.net
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      years: [
        '2025',
        '2026',
        '2027',
        '2028',
        '2029',
        '2030',
        '2031',
        '2032',
        '2033',
        '2034',
        '2035',
        '2036',
        '2037',
        '2038',
        '2039',
        '2040',
        '2041',
        '2042',
        '2043',
        '2044'
      ],
      cc_num_fail: '4222222222222',
      cc_num_pass: '4007000000027',
      cc_num: '',
      cc_cid: '',
      cc_exp_month: '01',
      cc_exp_year: '2030'
    };
  },
  created() {
    if (this.authStore.profile) {
      this.zip = this.authStore.profile.zip;
    } else {
      this.zip = this.authStore.zip;
    }
    this.items = this.authStore.cartItems;
    this.masterItemIds = this.items.map((item) => item.id);
    if (this.authStore.isProfileLoggedIn) {
      this.profile = this.authStore.profile;
    }

    //
    if (
      this.authStore.paymentOptions.length > 0 &&
      this.authStore.shippingOptions.length > 0
    ) {
      // auto select the first payment and shipping options
      this.shippingCalculated = true;
      this.paymentOptionSelected = this.authStore.paymentOptions[0].value;
      this.shippingOptionSelected = this.authStore.shippingOptions[0].value;
    }
  },
  methods: {
    goToItem(url) {
      console.log('goToItem', url);
      window.location.href = url;
    },
    saveOrder() {
      if (!this.validate()) {
        return;
      }

      let order_schema = this.profile;
      // order_schema['profile'] = this.profile;
      order_schema['employee_id'] = this.authStore.getEmployeeId;
      order_schema['memo'] = this.memo;
      order_schema['checkout_type'] = this.paymentOptionSelected;
      order_schema['shipping_option'] = this.shippingOptionSelected;
      order_schema['order_items'] = this.authStore.cartItems;

      order_schema['tax_total'] = this.authStore.cartTaxTotal;
      order_schema['total'] = this.authStore.cartTotal;

      if (this.paymentOptionSelected == 'cc') {
        order_schema['cc_num'] = this.cc_num;
        order_schema['cc_cid'] = this.cc_cid;
        order_schema['cc_exp_date'] = this.cc_exp_year + '-' + this.cc_exp_month;
      }

      axios
        .post(this.apiUrl + 'order', order_schema)
        .then((response) => {
          this.authStore.clearCart();
          this.$router.push({
            path: `/profile/orders/${response.data.orderid}/${response.data.email}`
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage = error.response.data.detail;
          } else if (error.message) {
            this.errorMessage = error.message;
          }
          console.log(this.errorMessage);
        });
    },
    validate() {
      this.errorMessage = '';
      if (this.termsClicked == 0) {
        this.validTerms = false;
        return false;
      }
      this.validTerms = true;
      return true;
    },
    showModalTerms() {
      let myModal = new window.bootstrap.Modal(document.getElementById('modal-terms'));
      myModal.show();
    },
    deleteItem(index) {
      this.calculatedTaxes = false;
      this.authStore.removeFromCart(index);
      this.resetShipping();
    },
    resetShipping() {
      console.log('resetShipping');
      // force recalculation of shipping
      this.authStore.shippingOptions = [];
      this.shippingCalculated = false;
    },
    getShippingTaxes() {
      this.shippingCalculated = false;
      if (this.zip == '') {
        this.validZip = false;
        this.validZipMessage = 'Please check your zip code and try again.';
        return;
      }

      this.authStore.zip = this.zip;

      const data = {
        destination_zip: this.zip,
        profile_company_id: this.authStore.getProfileCompanyId,
        items: this.authStore.cartItems
      };

      axios
        .post(this.apiUrl + 'get_shipping_taxes', data)
        .then((response) => {
          this.validZip = true;
          this.items = response.data.items;
          this.authStore.paymentOptions = response.data.payment_options;
          this.authStore.shippingOptions = response.data.shipping_options;
          this.authStore.addAllItems(response.data.items);

          // auto select the first payment and shipping options
          this.paymentOptionSelected = response.data.payment_options[0].value;
          this.shippingOptionSelected = response.data.shipping_options[0].value;
          this.shippingCalculated = true;
        })
        .catch((error) => {
          this.validZip = false;

          if (error.response && error.response.data && error.response.data.detail) {
            this.validZipMessage = error.response.data.detail;
          } else {
            this.validZipMessage = error.message;
          }
        });
    }
  }
};
</script>

<template>
  <div class="item-container">
    <h4>Shopping Cart</h4>

    <!--- Midcity -->
    <div v-if="clientName === 'midcity'">
      Please have orders in by
      <span style="text-decoration: underline">
        3pm for Oahu and 2pm for Outer Island the day before your delivery needs to go
        out.</span
      ><br />
      If orders are received after that time, it will be delivered the next scheduled
      delivery day.<br /><br />

      <p>
        <strong>Oahu Delivery Schedule:</strong><br />
        <span class="del_label">Monday:</span> Town (Airport -&gt; Kahala)<br />
        <span class="del_label">Tuesdays:</span> Set in Place Orders<br />
        <span class="del_label">Wednesday:</span> Town, Aiea, West, Wahiawa, Mililani<br />
        <span class="del_label">Thursday:</span> Kailua, Kaneohe, North Shore, Waimea Bay,
        Haleiwa, Waimanalo<br />
        <span class="del_label">Friday:</span> Aiea, Town, Hawaii Kai, West, Wahiawa,
        Mililani
      </p>
    </div>
    <!--- Midcity -->

    <table class="table" boarder="1">
      <tr>
        <th>Item</th>
        <th>Name</th>
        <th>Quantity</th>
        <th class="text-end">Price</th>
        <td></td>
      </tr>
      <tbody>
        <tr v-for="(item, index) in items" :key="item.id">
          <td>
            <img :src="item.thumb_image_url" class="thumb-image" />
          </td>
          <td>
            <div class="hand" @click="goToItem(item.url_link)">
              {{ item.title }}
            </div>
            <div v-if="item.options">
              <small>{{ item.options }}</small>
            </div>
          </td>
          <td>
            <select
              v-model="item.qty"
              class="form-select form-select-sm no-width float-start"
              @change="resetShipping()"
            >
              <option
                v-for="(qty_option, index) in item.qty_options"
                :key="index"
                :value="qty_option"
              >
                {{ qty_option }}
              </option>
            </select>
          </td>
          <td v-if="item.sell_price > 0 && item.show_pricing == 1" class="text-end">
            {{ $money(item.sell_price) }}
          </td>
          <td v-if="item.sell_price == 0 || item.show_pricing == 0" class="text-end">
            request quote
          </td>
          <td>
            <font-awesome-icon
              class="hand delete-item"
              icon="ml-2 fa-x fs-6"
              @click="deleteItem(index)"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td nowrap>Sub Total:</td>
          <td class="text-end">{{ $money(authStore.cartSubTotal) }}</td>
        </tr>
        <tr v-if="shippingCalculated">
          <td colspan="2"></td>
          <td nowrap>Freight Total:</td>
          <td class="text-end">{{ $money(authStore.cartShippingTotal) }}</td>
        </tr>
        <tr v-if="shippingCalculated">
          <td colspan="2"></td>
          <td>Tax Total:</td>
          <td class="text-end">{{ $money(authStore.cartTaxTotal) }}</td>
        </tr>
        <tr v-if="shippingCalculated">
          <td colspan="2"></td>
          <td>Total:</td>
          <td class="text-end">{{ $money(authStore.cartTotal) }}</td>
        </tr>
      </tbody>
    </table>

    <input
      id="zip"
      v-model="zip"
      type="text"
      class="form-control no-width d-inline px-2"
      placeholder="ZIP Code"
    />

    <button class="btn btn-primary ms-2" @click="getShippingTaxes()">
      Get Shipping Taxes
    </button>
    <div v-if="!validZip" class="invalid-form">{{ validZipMessage }}</div>
  </div>

  <div v-if="shippingCalculated">
    <!--- Suggested Add Ons
    <AddonComponent :master_item_ids="masterItemIds"></AddonComponent> -->

    <!--- Shipping Options -->
    <div class="item-container mt-3">
      <h4>Shipping</h4>
      <div
        v-for="shippingOption in authStore.shippingOptions"
        :key="shippingOption"
        class="form-check"
      >
        <input
          v-model="shippingOptionSelected"
          class="form-check-input"
          type="radio"
          name="shipping_option"
          :value="shippingOption.value"
        />
        <label class="form-check-label">{{ shippingOption.name }}</label>
      </div>
    </div>

    <!--- Payment Options -->
    <div class="item-container mt-3">
      <h4>Payment Options</h4>

      <div
        v-for="paymentOption in authStore.paymentOptions"
        :key="paymentOption"
        class="form-check"
      >
        <input
          v-model="paymentOptionSelected"
          class="form-check-input"
          type="radio"
          name="payment_option"
          :value="paymentOption.value"
        />
        <label class="form-check-label">{{ paymentOption.name }}</label>

        <!--- cc form -->
        <div v-if="paymentOption.value == 'cc'">
          <img src="@/assets/cc_amex.gif" alt="credit cards" class="" />

          <table class="table">
            <tr>
              <td class="form-label">card number</td>
              <td><input v-model="cc_num" type="text" class="form-control cc_num" /></td>
            </tr>
            <tr>
              <td class="form-label">exp date</td>
              <td>
                <select v-model="cc_exp_month" class="form-select no-width inline">
                  <option v-for="month in months" :key="month" :value="month">
                    {{ month }}
                  </option>
                </select>
                -
                <select v-model="cc_exp_year" class="form-select no-width inline">
                  <option v-for="year in years" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="form-label">cvv</td>
              <td><input v-model="cc_cid" type="text" class="form-control cc_cid" /></td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <!--- Address -->
    <div class="item-container mt-3">
      <h4>Address</h4>

      <table class="table">
        <tr>
          <td></td>
          <td><b>Bill To</b></td>
          <td><b>Ship To</b></td>
        </tr>
        <tr>
          <td class="form-label">company</td>
          <td style="width: 40%">
            <input v-model="profile.billto_company" class="form-control" type="text" />
          </td>
          <td style="width: 40%">
            <input v-model="profile.company" class="form-control" type="text" />
          </td>
        </tr>
        <tr>
          <td class="form-label">firstname</td>
          <td>
            <input v-model="profile.billto_firstname" class="form-control" type="text" />
          </td>
          <td>
            <input v-model="profile.firstname" class="form-control" type="text" />
          </td>
        </tr>
        <tr>
          <td class="form-label">lastname</td>
          <td>
            <input v-model="profile.billto_lastname" class="form-control" type="text" />
          </td>
          <td>
            <input v-model="profile.lastname" class="form-control" type="text" />
          </td>
        </tr>
        <tr>
          <td class="form-label">email</td>
          <td>
            <input v-model="profile.billto_email" class="form-control" type="email" />
          </td>
          <td>
            <input v-model="profile.email" class="form-control" type="email" />
          </td>
        </tr>
        <tr>
          <td class="form-label">phone</td>
          <td>
            <input v-model="profile.billto_phone" class="form-control" type="text" />
          </td>
          <td>
            <input v-model="profile.phone" class="form-control" type="text" />
          </td>
        </tr>
        <tr>
          <td class="form-label">address</td>
          <td>
            <input v-model="profile.billto_address1" class="form-control" type="text" />
          </td>
          <td>
            <input v-model="profile.address1" class="form-control" type="text" />
          </td>
        </tr>
        <tr>
          <td class="form-label">address2</td>
          <td>
            <input v-model="profile.billto_address2" class="form-control" type="text" />
          </td>
          <td>
            <input v-model="profile.address2" class="form-control" type="text" />
          </td>
        </tr>
        <tr>
          <td class="form-label">city state zip</td>
          <td>
            <input
              v-model="profile.billto_city"
              class="form-control no-width inline"
              type="text"
              placeholder="city"
            />

            <select
              v-model="profile.billto_state"
              class="form-select no-width inline mx-1"
            >
              <option v-for="state in state_codes" :key="state" :value="state">
                {{ state }}
              </option>
            </select>

            <input
              v-model="profile.billto_zip"
              class="form-control no-width inline"
              type="text"
              placeholder="zip"
            />
          </td>
          <td>
            <input
              v-model="profile.city"
              class="form-control no-width inline"
              type="text"
              placeholder="city"
            />

            <select v-model="profile.state" class="form-select no-width inline mx-1">
              <option v-for="state in state_codes" :key="state" :value="state">
                {{ state }}
              </option>
            </select>

            <input
              v-model="zip"
              class="form-control no-width inline"
              type="text"
              placeholder="zip"
            />
          </td>
        </tr>
        <tr>
          <td class="form-label">country</td>
          <td>
            <select v-model="profile.billto_country" class="form-select">
              <option v-for="country in country_codes" :key="country" :value="country">
                {{ country }}
              </option>
            </select>
          </td>
          <td>
            <select v-model="profile.country" class="form-select pe-2">
              <option v-for="country in country_codes" :key="country" :value="country">
                {{ country }}
              </option>
            </select>
          </td>
        </tr>
      </table>
    </div>

    <!--- Memo -->
    <div class="item-container mt-3">
      <h4>Memo</h4>

      <textarea v-model="memo" class="form-control"></textarea>
    </div>

    <!--- Terms and Conditions -->
    <div class="item-container mt-3">
      <h4>Terms and Conditions</h4>

      <div class="hand mt-3">
        <input
          v-model="termsClicked"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
          @change="validate()"
        />
        <span @click="showModalTerms"> I agree to the terms and conditions </span>
        <div v-if="!validTerms" class="invalid-form">Terms is required</div>
      </div>
      <ModalPopupTerms></ModalPopupTerms>
    </div>

    <!--- errorMessage -->
    <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
      {{ errorMessage }}
    </div>

    <button class="btn btn-primary mt-3" @click="saveOrder()">Submit Order</button>
  </div>
</template>

<style scoped>
.del_label {
  text-align: right;
  width: 100px;
  display: inline-block;
  font-size: small;
  font-style: italic;
}
.cc_num {
  width: 100%;
  max-width: 300px;
}
.cc_cid {
  width: 100%;
  max-width: 100px;
}
</style>
