<script>
import axios from 'axios';
import router from '../../router/index.js';
import { useRoute } from 'vue-router';

export default {
  data() {
    return {
      profiles: [],
      resultList: [],
      paginationTotalRecords: 0,
      paginationMaxRecordsPerPage: 1,
      paginationCurrentPage: 1,

      employeeList: [],
      warehouseList: [
        { id: '', name: '' },
        { id: 'Indy', name: 'Indy' },
        { id: 'Grand Rapids', name: 'Grand Rapids' },
        { id: 'South Bend', name: 'South Bend' },
        { id: 'Ft Wayne', name: 'Ft Wayne' }
      ],
      pricingTiers: [
        { id: 0, name: 'Standard' },
        { id: 1, name: 'Tier 1' },
        { id: 2, name: 'Tier 2' },
        { id: 3, name: 'Tier 3' },
        { id: 4, name: 'Tier 4' },
        { id: 5, name: 'Tier 5' },
        { id: 6, name: 'Tier 6' }
      ],
      profile_company: {
        id: 0,

        facts_number: '',
        //title: '',
        firstname: '',
        lastname: '',
        company: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
        phone: '',
        cell_phone: '',
        email: '',

        // billto_title: '',
        billto_firstname: '',
        billto_lastname: '',
        billto_company: '',
        billto_address1: '',
        billto_address2: '',
        billto_city: '',
        billto_state: '',
        billto_zip: '',
        billto_country: 'US',
        billto_phone: '',
        billto_cell_phone: '',
        billto_email: '',

        destination_residence: 0,
        destination_insidedelivery: 0,
        destination_liftgatedelivery: 0,
        employee_id: 0,
        latitude: '',
        longitude: '',

        //cobrand: 'default',
        allow_invoicing: 0,
        //free_freight: 0,
        pricing_tier: 1,
        tax_exempt: 0,
        nick_name: '',
        warehouse: '',
        notes: ''
      },
      country_codes: ['US', 'CA'],
      state_codes: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',

        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'NT',
        'NU',
        'ON',
        'PE',
        'QC',
        'SK',
        'YT'
      ]
    };
  },
  computed: {
    total() {
      return (
        Math.round(this.resultList.reduce((total, item) => total + item.total, 0) * 100) /
        100
      );
    }
  },
  mounted() {
    // get orders
    this.getAllresultList(1);

    // API get profile
    if (this.$route.params.profile_company_id > 0) {
      axios
        .get(
          this.apiUrl + 'admin/profile/company/' + this.$route.params.profile_company_id
        )
        .then((response) => {
          this.profile_company = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    }
    // get employee dropdown
    axios
      .get(this.apiUrl + 'admin/employee/dropdowns')
      .then((response) => {
        this.employeeList = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });

    // get buyers
    axios
      .get(this.apiUrl + 'admin/profile/buyers/' + this.$route.params.profile_company_id)
      .then((response) => {
        this.profiles = response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.detail) {
          this.showToast('Error', error.response.data.detail, 'error');
        } else {
          this.showToast('Error', error.message, 'error');
        }
      });
  },
  methods: {
    cancelProfile() {
      this.$router.push({ path: '/admin/profile' });
    },
    saveProfile() {
      axios
        .post(this.apiUrl + 'admin/profile/company/save', this.profile_company)
        .then((response) => {
          this.showToast('Data saved', '', 'success');
          this.$router.push({ path: '/admin/profile' });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },

    // same as on ProfileList page
    modifyProfile(profile_id, profile_company_id) {
      router.push({ path: '/admin/profile/' + profile_id + '/' + profile_company_id });
    },
    deleteProfile(profile_id, index) {
      if (confirm('Do you really want to delete?')) {
        axios
          .delete(this.apiUrl + 'admin/profile/' + profile_id)
          .then(() => {
            this.resultList.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // auto login as profile - on ProfileList.vue and ProfileAddMod.vue
    async loginProfile(profileid) {
      const data = { profileid };
      await axios
        .post(this.apiUrl + 'admin/profile/login-as', data)
        .then((response) => {
          this.authStore.cartItems = [];
          this.authStore.profile = response.data;

          // convert to array of ids
          this.authStore.profile.order_quide_item_ids =
            this.authStore.profile.order_quide_item_ids.split(',');

          this.authStore.profile.profile_assigned_to_item_ids =
            this.authStore.profile.profile_assigned_to_item_ids.split(',');

          if (this.authStore.hasOrderGuide) {
            router.push({ path: '/profile/order-guide' });
          } else {
            router.push({ path: '/' });
          }
        })
        .catch((error) => {
          this.authStore.profile = [];
          if (error.response && error.response.data && error.response.data.detail) {
            this.showToast('Error', error.response.data.detail, 'error');
          } else {
            this.showToast('Error', error.message, 'error');
          }
        });
    },

    getAllresultList(page) {
      if (this.$route.params.profile_company_id > 0) {
        let filters = {
          profile_company_id: this.$route.params.profile_company_id,
          status: ''
        };

        axios
          .post(this.apiUrl + 'admin/orders?page_number=' + page, filters)
          .then((response) => {
            this.resultList = response.data.items;
            this.paginationTotalRecords = response.data.total;
            this.paginationMaxRecordsPerPage = response.data.page_size;
            this.paginationCurrentPage = response.data.page_number;
          })
          .catch((error) => {
            console.error('Error fetching orders:', error);
          });
      }
    }
  }
};
</script>

<template>
  <div class="d-flex stickyXXX align-items-center">
    <h2>Company Profile / Add Mod</h2>
    <div class="ms-auto">
      <button class="btn btn-light btn-sm mx-3" @click="cancelProfile">Cancel</button>
      <button class="btn btn-primary btn-sm" @click="saveProfile">Save</button>
    </div>
  </div>

  <table class="table">
    <tr>
      <td class="form-label">FACTS</td>
      <td>
        <input v-model="profile_company.facts_number" class="form-control" type="text" />
      </td>
    </tr>

    <tr>
      <td class="form-label">allow invoicing</td>
      <td>
        <input
          v-model="profile_company.allow_invoicing"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">tax exempt</td>
      <td>
        <input
          v-model="profile_company.tax_exempt"
          class="form-check-input"
          type="checkbox"
          true-value="1"
          false-value="0"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">pricing tier</td>
      <td>
        <select v-model="profile_company.pricing_tier" class="form-select form-select-sm">
          <option
            v-for="pricingTier in pricingTiers"
            :key="pricingTier.id"
            :value="pricingTier.id"
          >
            {{ pricingTier.name }}
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td class="form-label">warehouse</td>
      <td>
        <select v-model="profile_company.warehouse" class="form-select form-select-sm">
          <option
            v-for="warehouse in warehouseList"
            :key="warehouse.id"
            :value="warehouse.id"
          >
            {{ warehouse.name }}
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td class="form-label">sales person</td>
      <td>
        <select v-model="profile_company.employee_id" class="form-select form-select-sm">
          <option
            v-for="employee in employeeList"
            :key="employee.id"
            :value="employee.id"
          >
            {{ employee.name }}
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td class="form-label">notes</td>
      <td>
        <textarea
          v-model="profile_company.notes"
          class="form-control"
          rows="3"
        ></textarea>
      </td>
    </tr>

    <tr>
      <td></td>
      <td><b>Bill To</b></td>
      <td><b>Ship To</b></td>
    </tr>
    <tr>
      <td class="form-label">company</td>
      <td style="width: 40%">
        <input
          v-model="profile_company.billto_company"
          class="form-control"
          type="text"
        />
      </td>
      <td style="width: 40%">
        <input v-model="profile_company.company" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">firstname</td>
      <td>
        <input
          v-model="profile_company.billto_firstname"
          class="form-control"
          type="text"
        />
      </td>
      <td>
        <input v-model="profile_company.firstname" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">lastname</td>
      <td>
        <input
          v-model="profile_company.billto_lastname"
          class="form-control"
          type="text"
        />
      </td>
      <td>
        <input v-model="profile_company.lastname" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">email</td>
      <td>
        <input v-model="profile_company.billto_email" class="form-control" type="email" />
      </td>
      <td><input v-model="profile_company.email" class="form-control" type="email" /></td>
    </tr>
    <tr>
      <td class="form-label">phone</td>
      <td>
        <input v-model="profile_company.billto_phone" class="form-control" type="text" />
      </td>
      <td><input v-model="profile_company.phone" class="form-control" type="text" /></td>
    </tr>
    <tr>
      <td class="form-label">address</td>
      <td>
        <input
          v-model="profile_company.billto_address1"
          class="form-control"
          type="text"
        />
      </td>
      <td>
        <input v-model="profile_company.address1" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">address2</td>
      <td>
        <input
          v-model="profile_company.billto_address2"
          class="form-control"
          type="text"
        />
      </td>
      <td>
        <input v-model="profile_company.address2" class="form-control" type="text" />
      </td>
    </tr>
    <tr>
      <td class="form-label">city state zip</td>
      <td>
        <input
          v-model="profile_company.billto_city"
          class="form-control no-width inline"
          type="text"
          placeholder="city"
        />

        <select
          v-model="profile_company.billto_state"
          class="form-select no-width inline mx-1"
        >
          <option v-for="state in state_codes" :key="state" :value="state">
            {{ state }}
          </option>
        </select>

        <input
          v-model="profile_company.billto_zip"
          class="form-control no-width inline"
          type="text"
          placeholder="zip"
        />
      </td>
      <td>
        <input
          v-model="profile_company.city"
          class="form-control no-width inline"
          type="text"
          placeholder="city"
        />

        <select v-model="profile_company.state" class="form-select no-width inline mx-1">
          <option v-for="state in state_codes" :key="state" :value="state">
            {{ state }}
          </option>
        </select>

        <input
          v-model="profile_company.zip"
          class="form-control no-width inline"
          type="text"
          placeholder="zip"
        />
      </td>
    </tr>
    <tr>
      <td class="form-label">country</td>
      <td>
        <select v-model="profile_company.billto_country" class="form-select">
          <option v-for="country in country_codes" :key="country" :value="country">
            {{ country }}
          </option>
        </select>
      </td>
      <td>
        <select v-model="profile_company.country" class="form-select">
          <option v-for="country in country_codes" :key="country" :value="country">
            {{ country }}
          </option>
        </select>
      </td>
    </tr>
  </table>

  <h3>Buyers</h3>
  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <th>Name</th>
        <th>Email</th>
        <td class="text-center">Login</td>
        <td class="text-center">Modify</td>
        <td class="text-center">Delete</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="profile in profiles" :key="profile">
        <td>{{ profile.firstname }} {{ profile.lastname }}</td>
        <td>{{ profile.email }}</td>

        <td class="text-center">
          <span v-if="profile.profileid > 0">
            <a class="hand" @click="loginProfile(profile.profileid)">
              <font-awesome-icon icon="fa-sign-in" /> </a
          ></span>
        </td>
        <td class="text-center">
          <span v-if="profile.profileid > 0">
            <a
              class="hand"
              @click="modifyProfile(profile.profileid, profile.profile_company_id)"
            >
              <font-awesome-icon icon="fa-pen" /> </a
          ></span>
        </td>
        <td class="text-center">
          <span v-if="profile.profileid > 0">
            <a class="hand" @click="deleteProfile(profile.profileid, index)">
              <font-awesome-icon icon="fa-trash-alt" /> </a
          ></span>
        </td>
      </tr>
    </tbody>
  </table>

  <h3>Orders</h3>

  <!--- this code is duped on 3 pages -->
  <div class="pagination-container">
    <vue-awesome-paginate
      v-model="paginationCurrentPage"
      :total-items="paginationTotalRecords"
      :items-per-page="paginationMaxRecordsPerPage"
      :max-pages-shown="10"
      :on-click="processPagination"
    />
  </div>

  <table class="table table-hover table-sm">
    <thead class="table-light">
      <tr>
        <th>Order ID</th>
        <th>Cobrand</th>
        <th>Date Quoted</th>
        <th>Name</th>
        <th>Status</th>
        <th>Payment Type</th>
        <th>Internal Memo</th>
        <th>Customer Memo</th>
        <th>Total</th>
        <th>Due</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="order in resultList" :key="order.orderid">
        <td>
          <a href="#" @click="viewOrder(order.orderid, order.email)">
            {{ order.orderid }}
          </a>
        </td>
        <td>{{ order.cobrand }}</td>
        <td>{{ $getDateTime(order.date_quoted) }}</td>
        <td>{{ order.billto_firstname }} {{ order.billto_lastname }}</td>
        <td>{{ order.status }}</td>
        <td>{{ order.checkout_type }}</td>
        <td>{{ order.memo }}</td>
        <td>{{ order.customer_memo }}</td>
        <td class="right">{{ order.total }}</td>
        <td class="right">{{ order.total_due }}</td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td class="right">Total</td>
        <td>
          <b class="right">{{ total }}</b>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>
