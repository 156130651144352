import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import router from '../router';


export const useAuthStore = defineStore('auth', {
  state: () => ({
    employee: useLocalStorage("storeEmployee", []),
    profile: useLocalStorage("storeProfile", []),
    sections: useLocalStorage("sections", []),
    // cart
    cartItems: useLocalStorage("cartItems", []),
    paymentOptions: useLocalStorage("paymentOptions", []),
    shippingOptions: useLocalStorage("shippingOptions", []),
    zip: useLocalStorage("zip", []),
  }),
  actions: {       
    // cart ----------------------------------------------------------------------------------
    addAllItems(items) {
      this.cartItems = items;
    },
    addToCart(item) {
      // update qty if item is already in cart
      for (let i=0; i < this.cartItems.length; i++) {
        if (this.cartItems[i].id == item.id) {
          this.cartItems[i].qty += item.qty;
          this.showToastCart(item.thumb_image_url, item.title, item.manufacturer);
          return;
        }
      }
      this.showToastCart(item.thumb_image_url, item.title, item.manufacturer);
      this.cartItems.push(item);
      this.resetCart();
    },        
    removeFromCart(index) {
      this.cartItems.splice(index, 1);
      this.resetCart();
    },
    resetCart() {
      for (let i = 0; i < this.cartItems.length; i++) {
        this.cartItems[i].tax_total = 0;
        this.cartItems[i].freight_rate_1 = 0;
      }
      this.shippingOptions = [];
      this.paymentOptions = [];
    },
    clearCart() {
      this.cartItems = [];
      this.resetCart();
    },
    showToastCart(image_src, title, manufacturer = '') {
      const toastLiveExample = document.getElementById('toastCart');
      const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample);

      // set title, message, small
      document.getElementById('toast_cart_title').innerHTML = title;
      document.getElementById('toast_cart_manufacturer').innerHTML = manufacturer;
      document.getElementById('toast_cart_image').src = image_src;

      toastBootstrap.show();
    },    
    closeToastCart() {
      const toastLiveExample = document.getElementById('toastCart');
      const toastBootstrap = new bootstrap.Toast(toastLiveExample);      
      toastBootstrap.hide();
    },

    // employee ----------------------------------------------------------------------------------
    logout() {
      this.profile =  [];
      this.employee =  [];
      this.zip =  [];
      this.cartItems =  [];
      this.shippingOptions =  [];
      router.push({ path: '/admin/login' })
    },

    // profile ----------------------------------------------------------------------------------
    profileLogout() {
      this.profile =  [];
      router.push({ path: '/login' })
    },
  },
  getters: {    
    totalItemsInCart(){
      return this.cartItems.length 
    },    
    cartTotal() {
      // round to 2 decimal places
      let total = (this.cartSubTotal + this.cartTaxTotal + this.cartShippingTotal);
      return Math.round(total*100)/100;
    }, 
    cartSubTotal() {
      let total = 0;
      let items = this.cartItems;

      for (let i = 0; i < items.length; i++) {
        total += items[i].sell_price * items[i].qty;
      }
      return Math.round(total*100)/100;
    },
    cartTaxTotal() {
      let total = 0;
      let items = this.cartItems;

      for (let i = 0; i < items.length; i++) {
        total += items[i].tax_total;
      }
      return Math.round(total*100)/100;
    },
    cartShippingTotal() {
      let total = 0;
      let items = this.cartItems;

      for (let i = 0; i < items.length; i++) {
        total += items[i].freight_rate_1 + items[i].freight_tax_total_1;
        total += items[i].freight_rate_2 + items[i].freight_tax_total_2;
        total += items[i].freight_rate_3 + items[i].freight_tax_total_3;
      }
      return Math.round(total*100)/100;
    },


    isLoggedIn() {
      return this.employee.id;
    },
    isProfileLoggedIn() {
      return this.profile && this.profile.profile_company_id;
    },
    hasOrderGuide() {
      return this.profile && this.profile.order_quide_item_ids && (this.profile.order_quide_item_ids.length > 2);
    },
    hasAssignedItems() {
      return this.profile && this.profile.profile_assigned_to_item_ids && (this.profile.profile_assigned_to_item_ids.length > 2);
    },
    getEmployeeId() {
      return this.employee.id ? this.employee.id : 0;
    },
    getProfileCompanyId() {
      return this.profile.profile_company_id ? this.profile.profile_company_id : 0;
    },
  }
})