<script>
import { useAuthStore } from '@/stores/authStore';
import OptionsComponent from '../components/OptionsComponent.vue';
import { toRaw } from 'vue';
import axios from 'axios';
// loop "qty_options" in a select drop
export default {
  components: {
    OptionsComponent
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      qty: this.item.qty_options[0],

      color_options: this.item.color_options[0],
      gas_options: this.item.gas_options[0],
      electrical_options: this.item.electrical_options[0],
      misc_options: this.item.misc_options[0]
    };
  },
  watch: {
    profile_company_id() {
      this.checkItemExists;
    },
    item_id() {
      this.checkItemExists;
    }
  },
  mounted() {
    this.color_options = this.item.color_options[0];
    this.gas_options = this.item.gas_options[0];
    this.electrical_options = this.item.electrical_options[0];
    this.misc_options = this.item.misc_options[0];
  },

  methods: {
    async checkItemExists() {
      if (this.profile_company_id && this.item_id) {
        try {
          const response = await axios.post(this.apiURL + '/profile/order_guides', {
            profile_company_id: this.profile_company_id,
            item_id: this.item_id
          });
          this.itemExists = response.data.exists;
        } catch (error) {
          console.error('Error Checking item existence:', error);
          this.itemExists = 'error';
        }
      } else {
        this.itemExists = null;
      }
    },
    combineStrings(...args) {
      return args.filter(Boolean).join(', ');
    },
    updateColorOptions(value) {
      this.color_options = value;
    },
    updateGasOptions(value) {
      this.gas_options = value;
    },
    updateElecticalOptions(value) {
      this.electrical_options = value;
    },
    updateMiscOptions(value) {
      this.misc_options = value;
    },
    addToCart(item) {
      const authStore = useAuthStore();
      let options = this.combineStrings(
        this.color_options,
        this.gas_options,
        this.electrical_options,
        this.misc_options
      );
      console.log('qty:', this.qty);
      item.qty = this.qty;
      item.options = options;
      authStore.addToCart(item);
      authStore.resetCart();
    }
  }
};
</script>

<template>
  <div class="float-end">
    <div v-if="item.color_options.length > 0" class="mb-2">
      <OptionsComponent
        label="Color"
        :value="color_options"
        :options="item.color_options"
        @updated-option-event="updateColorOptions"
      ></OptionsComponent>
    </div>
    <div v-if="item.gas_options.length > 0" class="mb-2">
      <OptionsComponent
        label="Gas"
        :value="gas_options"
        :options="item.gas_options"
        @updated-option-event="updateGasOptions"
      ></OptionsComponent>
    </div>

    <div v-if="item.electrical_options.length > 0" class="mb-2">
      <OptionsComponent
        label="Electrical"
        :value="electrical_options"
        :options="item.electrical_options"
        @updated-option-event="updateElecticalOptions"
      ></OptionsComponent>
    </div>

    <div v-if="item.misc_options.length > 0" class="mb-2">
      <OptionsComponent
        label=""
        :value="misc_options"
        :options="item.misc_options"
        @updated-option-event="updateMiscOptions"
      ></OptionsComponent>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="add-container float-end">
    <select v-model="qty" class="form-select form-select-sm no-width float-start">
      <option
        v-for="(qty_option, index) in item.qty_options"
        :key="index"
        :value="qty_option"
      >
        {{ qty_option }}
      </option>
    </select>
    <button class="btn btn-sm hand btn-danger" @click="addToCart(item)">
      add to cart
    </button>
  </div>
  <div class="clearfix"></div>
</template>

<style scoped>
.add-container {
  display: flex;
  justify-content: start;
  align-items: center;
}
.no-width {
  width: unset;
}
</style>
